import React from 'react'
import Root from '../../components/root'

const PayButton = () => (
	<p className="payment">
		<span>PRE-ORDER CLOSED!</span>
	</p>
)

const DmcPreOrder = () => {
	return (
		<Root title="Developer Marketing Course Pre-Order">
			<div className="dmc article content">
				<h1 align="center">Developer Marketing Course Pre-Order</h1>

				<p>
					Hi there{' '}
					<span role="img" aria-label="wave">
						👋🏻
					</span>
					,
				</p>
				<p>I am launching a new course called "Developer Marketing".</p>
				<p>Here's what the course (& the exercises) will enable you to do -</p>
				<ol>
					<li>
						<strong>Recruiters start contacting you</strong> rather than the other way around.
					</li>
					<li>
						You get international <strong>US and EU based opportunities</strong> right in your LinkedIn inbox or email.
					</li>
					<li>
						You always have <strong>too many job options</strong> to choose from.
					</li>
					<li>
						You <strong>get paid</strong> what other <strong>top developers</strong> are getting paid.
					</li>
					<li>
						You are able to <strong>present yourself as an expert</strong>, just like other "expert" software
						developers.
					</li>
					<li>
						You are invited for <strong>international speaking gigs</strong> regardless of your experience.
					</li>
					<li>
						You are able to <strong>build an attractive GitHub profile</strong> even if you have no clue how to do
						open-source.
					</li>
					<li>
						Your work is more visible in your company which makes you <strong>harder to fire</strong>.
					</li>
					<li>
						You <strong>get internship offers</strong> even without reaching out to companies (yes, the ideas talked in
						this course will work for students as well).
					</li>
				</ol>
				<p>And more.</p>
				<h3>Course Format</h3>
				<ol>
					<li>Recorded Videos.</li>
					<li>Exercises.</li>
					<li>New videos will be added as we discover more topics that can be covered.</li>
				</ol>
				<h3>What Do You Get</h3>
				<ol>
					<li>Lifetime Access to this program.</li>
					<li>Lifetime Access to Future Updates.</li>
					<li>
						365 days Access to an exclusive community where I will be available for <strong>unlimited guidance</strong>.
						Want to ask 10 questions a day? You can.
					</li>
					<li>Lifetime Community of like-minded peers to learn from.</li>
					<li>
						90-days no questions asked <strong>moneyback guarantee</strong>.
					</li>
				</ol>
				<p>Sounds good? You can pre-order the course for the lowest ever price of ₹6999.</p>
				<p>
					<strong>The pre-order ends when 100 copies are sold.</strong>
				</p>
				<p class>
					<PayButton />
				</p>

				<h3>Frequently Asked Questions</h3>
				<h4>Will you teach coding in this course?</h4>
				<p>No. But if you need help learning something, I can point at the best resource to do so.</p>
				<h4>I am a student with little coding experience. Should I buy it?</h4>
				<p>
					Yes, you should. You are just starting your career so you are best positioned to take advantage of the
					knowledge shared in this course.
				</p>
				<h4>How do you know it will work for me?</h4>
				<p>I have tested this approach with me and a current college student. Needless to say, it works.</p>
				<h4>Does this course guarantee a job/internship?</h4>
				<p>No. This is a low-priced program which makes no such guarantees.</p>
				<h4>How is Lifetime defined?</h4>
				<p>Lifetime is a minimum of 5 years and more.</p>
				<hr />
				<p>
					<PayButton />
				</p>
			</div>
		</Root>
	)
}

export default DmcPreOrder
